import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { IconContext } from "react-icons";

const FeedBack = () => {
  const location = useLocation();
  const [mID, setMID] = useState(0);
  const [values, setValues] = useState("");
  const [resp, setResp] = useState(false);
  const BASE_API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setTimeout(() => {
      const lc = location.search;
      const mId = new URLSearchParams(lc).get("mid");
      // console.log(mId);
      setMID(mId);
    }, 100);
  }, []);

  function handleChange(event) {
    const value = event.target.value;
    setValues(value);
  }

  const handleSubmit = () => {
    const joke = {
      acceptCode: mID,
      feedback: values,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'X-RapidAPI-Key': 'your-api-key',
        // 'X-RapidAPI-Host': 'jokes-by-api-ninjas.p.rapidapi.com',
      },
      body: JSON.stringify(joke),
    };
    fetch(`${BASE_API_URL}/ActivityTrack/SaveEmpFeedback`, options)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        //console.log(data);
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        setResp(data.isSuccess);
        // setMessage(data.message);
      })
      .catch((error) => {
        console.error(error);
        setResp(false);
        // setMessage(error);
      });
  };

  return (
    <div
      className="App"
      // style={{
      //   backgroundImage:
      //     "url(https://crmaddon.anantayitsolutions.com/media/logos/CRM_logo.png)",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: 'cover',
      //   // backgroundPosition: "center",
      //   width: '100vw',
      //   height: '100vh',
      // }}
    >
      <div className="vh-100 d-flex justify-content-center align-items-center">
        {resp ? (
          <IconContext.Provider
            value={{
              color: "green",
              size: "7rem",
              className: "global-class-name",
            }}
          >
            <AiFillCheckCircle />
            <div className="text-center">
              <h1>Thank You for You Feedback!</h1>
              {/* <p>You have Successfully Accept our invitation. </p> */}
              {/* <button class="btn btn-primary">Back Home</button> */}
            </div>
          </IconContext.Provider>
        ) : (
          <div className="w-50">
            <h1>Please Give Your FeedBack</h1>
            <textarea
              autoFocus
              rows="4"
              className="form-control"
              value={values}
              onChange={handleChange}
            />
            {/* <p>You have Successfully Accept our invitation. </p> */}
            <button className="btn btn-primary mt-3" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedBack;
