import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import InvitaionPage from "./InvitaionPage";
import FeedBack from "./FeedBack";
import Error from "./Error";

function App() {
  return (
    <>
      {/* <h2 style={{color:'green',background:'grey', textAlign:'center',margin:'2px'}}>Welcome to CRM Addon Page</h2> */}
      {/* <BrowserRouter>
        <Routes>
          <Route path="/invitation" element={<InvitaionPage />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </BrowserRouter> */}
      <BrowserRouter>
        <Routes>
          <Route path="/activity/accept" element={<InvitaionPage />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/*" element={<Error />} />
        </Routes>
        {/* <Navigate from="/" to="/activity/accept" /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
