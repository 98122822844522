import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap-v5";
import { AiFillCheckCircle } from "react-icons/ai";
import { IconContext } from "react-icons";

function InvitaionPage() {
  const location = useLocation();
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  // const [mID, setMID] = useState("1A30009H20015");
  const [mID, setMID] = useState("");
  const [employee, setEmployee] = useState({
    loading: false,
    employee2: [],
  });
  const [loading, setloading] = useState(false);
  const [resp, setResp] = useState(false);
  const [message, setMessage] = useState("");
  const [activityMemberMapID, setActivityMemberMapID] = useState(0);
  const [memberCategoryID, setMemberCategoryID] = useState(0);
  const [activityID, setActivityID] = useState(0);
  const [memberID, setMemberID] = useState(0);
  const [organisationName, setOrganisationName] = useState("");
  const [noOfEmployee, setNoOfEmployee] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const lc = location.search;
      const mId = new URLSearchParams(lc).get("mid");
      setMID(mId);
      getActivityMemberData(mId);
    }, 100);
  }, []);

  const getActivityMemberData = (mId) => {
    const joke = {
      acceptCode: mId,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(joke),
    };
    fetch(
      `${BASE_API_URL}/ActivityTrack/GetOrgDtlByQCForInviTationReq`,
      options
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          toast.error(`${error}`);
        }
        setActivityMemberMapID(data.activityMemberMapID);
        setMemberCategoryID(data.memberCategoryID);
        setActivityID(data.activityID);
        setMemberID(data.memberID);
        setOrganisationName(data.organisationName);
        setMessage(data.message);
        setNoOfEmployee(data.noOfEmployee);
        let emp = [];
        for (let i = 0, len = data.noOfEmployee; i < len; i++) {
          let tmpCheckedData = {
            employeeID: i + 1,
            employeeName: "",
            empPosition: "",
            mobileNumber: "",
            empEmail: "",
          };
          emp.push(tmpCheckedData);
        }
        setEmployee({ ...employee, employee2: emp });
      })
      .catch((error) => {
        console.error(error);
        setMessage(error);
      });
  };

  // const [employeeName, setEmployeeName] = useState("");
  // const [empPosition, setEmpPosition] = useState("");
  // const [mobileNumber, setMobileNumber] = useState("");
  // const [empEmail, setEmpEmail] = useState("");
  let tcolor = { color: "purple" };

  function handleChange(e) {
    let tmpValue = e.target.value;
    let empId = e.target.id;
    let tmpName = e.target.name;
    const re = /^[0-9\b.]+$/;
    let tmpEmployeeData = [];
    tmpEmployeeData = employee.employee2;
    for (let k in tmpEmployeeData) {
      if (empId == `${tmpEmployeeData[k].employeeID}`) {
        if (tmpName == "employeeName") {
          tmpEmployeeData[k].employeeName = tmpValue;
        } else if (tmpName == "empPosition") {
          tmpEmployeeData[k].empPosition = tmpValue;
        } else if (tmpName == "empEmail") {
          tmpEmployeeData[k].empEmail = tmpValue;
        } else if (tmpName == "mobileNumber") {
          if (!isNaN(parseInt(tmpValue)) && re.test(tmpValue)) {
            tmpEmployeeData[k].mobileNumber = tmpValue;
          } else if (tmpValue == "") {
            tmpEmployeeData[k].mobileNumber = "";
          }
        }
        break;
      }
    }
    setEmployee({ ...employee, employee2: tmpEmployeeData });
  }

  const SendInvitation = () => {
    setloading(true);
    let tmpSector = employee.employee2;
    let strEmployeeName = "";
    let strEmpPosition = "";
    let strMobileNumber = "";
    let strEmpEmail = "";
    for (let k in tmpSector) {
      if (strEmployeeName == "") {
        strEmployeeName = `${tmpSector[k].employeeName}`;
      } else {
        strEmployeeName =
          strEmployeeName + "," + `${tmpSector[k].employeeName}`;
      }
      if (strEmpPosition == "") {
        strEmpPosition = `${tmpSector[k].empPosition}`;
      } else {
        strEmpPosition = strEmpPosition + "," + `${tmpSector[k].empPosition}`;
      }
      if (strMobileNumber == "") {
        strMobileNumber = `${tmpSector[k].mobileNumber}`;
      } else {
        strMobileNumber =
          strMobileNumber + "," + `${tmpSector[k].mobileNumber}`;
      }
      if (strEmpEmail == "") {
        strEmpEmail = `${tmpSector[k].empEmail}`;
      } else {
        strEmpEmail = strEmpEmail + "," + `${tmpSector[k].empEmail}`;
      }
    }
    const invReq = {
      activityMemberMapID: activityMemberMapID,
      activityMemberID: memberID,
      activityID: activityID,
      memberCategoryID: memberCategoryID,
      empNamelist: strEmployeeName,
      empPoslist: strEmpPosition,
      empMobList: strMobileNumber,
      empEmailList: strEmpEmail,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(invReq),
    };
    fetch(
      `${BASE_API_URL}/Invitation/SendEmailToOrgEmployeeForInvitation`,
      options
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setResp(data.isSuccess);
        setloading(false);
        // setMessage(data.message);
      })
      .catch((error) => {
        console.error(error);
        setResp(false);
      });
  };

  return (
    <>
      <div className="text-center">
        <img src={"/CRM_logo.png"} width={200} />
      </div>
      <div className="row text-center d-flex mt-2">
        <div className="d-block col-7">
          <h5 className="d-block mt-3">
            <b>Contact Number</b>
          </h5>
          <h6 className="d-block text-secondary mt-3">+256 312263850</h6>
        </div>
        <div className="d-block col-2 mb-3">
          <h5 className="d-block">
            <b>Email ID</b>
          </h5>
          <h6 className="d-block text-primary">psfu@psfuganda.org.ug</h6>
          <h6 className="d-block text-primary">www.psfuganda.org</h6>
        </div>
      </div>
      <div className="App">
        <div className="vh-90 d-flex justify-content-center align-items-center">
          {resp ? (
            <>
              <IconContext.Provider
                value={{
                  color: "green",
                  size: "7rem",
                  className: "global-class-name",
                }}
              >
                <AiFillCheckCircle />
                <div className="text-center mt-2 row">
                  <h1 className="col-12">Activity Registration Successfull!</h1>
                  {/* <div className="col-11 mt-3 text-end">
                    <Link className="btn btn-success" to="/">
                      Close
                    </Link>
                  </div> */}
                </div>
              </IconContext.Provider>
            </>
          ) : (
            <div className={`card py-0`} style={{ width: "85%" }}>
              <div
                className="card-header border-0 py-2"
                style={{ backgroundColor: "#009EF7" }}
              >
                <div className="row">
                  <div
                    className="fw-bolder fs-5 text-white text-center"
                    style={tcolor}
                  >
                    {organisationName}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped align-middle">
                    <thead style={{ backgroundColor: "#F1FAFF" }}>
                      <tr className="fw-bolder fs-6 text-dark">
                        <th className="min-w-50px">Employee Name</th>
                        <th className="min-w-50px">Employee Position</th>
                        <th className="min-w-50px">Mobile Number</th>
                        <th className="min-w-500px">Email</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {employee.employee2.length > 0 &&
                        employee.employee2.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <input
                                  // className="w-60"
                                  autoComplete="off"
                                  type="text"
                                  name="employeeName"
                                  id={`${data.employeeID}`}
                                  onChange={(e) => handleChange(e)}
                                  value={data.employeeName}
                                />
                              </td>
                              <td>
                                <input
                                  // className="w-60"
                                  autoComplete="off"
                                  type="text"
                                  name="empPosition"
                                  id={`${data.employeeID}`}
                                  onChange={(e) => handleChange(e)}
                                  value={data.empPosition}
                                />
                              </td>
                              <td>
                                <input
                                  // className="w-60"
                                  autoComplete="off"
                                  type="text"
                                  name="mobileNumber"
                                  id={`${data.employeeID}`}
                                  onChange={(e) => handleChange(e)}
                                  value={data.mobileNumber}
                                />
                              </td>
                              <td>
                                <input
                                  className="w-100"
                                  autoComplete="off"
                                  type="email"
                                  name="empEmail"
                                  id={`${data.employeeID}`}
                                  onChange={(e) => handleChange(e)}
                                  value={data.empEmail}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="text-end">
                  <Button
                    className="btn btn-success"
                    onClick={!loading && SendInvitation}
                    disabled={loading}
                  >
                    {!loading && "Submit"}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InvitaionPage;
