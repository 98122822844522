import React from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { IconContext } from "react-icons";

export default function Error() {
  return (
    <div className="text-center mt-5">
         <IconContext.Provider
                value={{
                  color: "red",
                  size: "3rem",
                  className: "global-class-name",
                }}
              >
         <AiFillCloseCircle />
      <h1>404 !</h1>
      <p>Page Didn't Found. </p>
      </IconContext.Provider>
    </div>
  );
}
